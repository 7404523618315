
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Toast, Dialog } from "vant";
import { Service } from "src/decorator";
import { OrderService, MedicalOrderService, WechatService } from "src/services";
import dayjs from "dayjs";
import { Pay, tools } from "src/utils";

@Component
export default class Goods extends Vue {
  // 状态类型映射
  private statusTypeMap: any = {
    Cancel: 0, //  已取消
    ToAppoint: 2, //  待预约
    ToPay: 4, //  待付款
    UnUsing: 6, //  待使用
    InUse: 7, //  使用中
    Appointed: 8, //  已预约
    Refunded: 10, //  已退款
    Completed: 12, //  已完成
    Applied: 14, //  已申请
    Verified: 16, //  已审核
    ToConfirm: 18, //  待确认
  };

  // 健康产品类型映射
  private healthServiceTypeMap: any = {
    Offline: 0, //  线下服务
    Online: 1, //  线上服务
    Assistance: 10, //  预约协助（绿通）
    Accompanying: 11, //  陪诊
  };

  // 产品类别映射
  private productCategoryMap: any = {
    greenway: 4, // 绿通
  };

  /**
   * 服务说明
   * @private
   * @returns boolean
   */
  private interpretOverlay: boolean = false;

  /**
   * 是否是ai跳转过来
   * @private
   * @returns boolean
   */
  private get isAi(): boolean {
    return this.$route.query && this.$route.query.isAi == "true" ? true : false;
  }

  /**
   * 数据
   * @private
   * @returns string
   */
  @Prop({ type: Object, default: () => ({}) })
  private item: any;

  /**
   * 是否显示日期
   * @private
   * @returns
   */
  private showDate: boolean = false;

  /**
   * 是否显示取消预约弹框
   * @private
   * @returns
   */
  private showCancelDialog: boolean = false;

  /**
   * 显示日期弹框
   * @private
   * @returns void
   */
  private showDateModal(): void {
    this.showDate = true;
  }

  private reportContent: string = "";

  /**
   * 我知道了
   * @private
   * @returns void
   */
  private async onKnow() {
    this.interpretOverlay = false;
  }

  /**
   * 去使用
   * @protected
   * @returns void
   */
  //  productsLink
  protected onApply(item) {
    if (item.productsLinkType == 2) {
      // 链接保存
      this.$router.push({ name: "medical-buy" });
      localStorage.setItem("productsLink", item.productsLink);
    }
  }

  /**
   * 获取当前登入信息
   * @private
   * @returns void
   */
  protected get userInfo(): any {
    return this.$store.getters.userInfo || {};
  }

  /**
   * 线上去使用
   * @protected
   * @returns void
   */
  private toUse(item) {
    // 无跳转 NOJUMP = 0
    //  小程序 APPLET = 1
    //  URL URL = 2
    if (item.productsLinkType == 0) {
      // 无跳转
      this.interpretOverlay = true;
    } else {
      // H5
      this.$router.push({ name: "medical-buy" }); // 链接保存
      localStorage.setItem("productsLink", item.productsLink);
    }
  }

  /**
   * dom加载完
   * @protected
   * @returns void
   */
  protected mounted() {
    this.init();
    this.reportContent = `
            <button class="test-btn"></button>
            <style>
                .test-btn{
                    width: 100%;
                    height: 520px;
                    background: transparent;
                    border: none;
                    color:rgba(255,255,255,0)
                }
                .test-btn:focus{outline: 0}
            </style>
            `;
    let type = null;
    if (this.item.productCategory == 2) {
      type = 0;
    } else {
      type = 1;
    }

    let data = {
      token: this.item.m78UserToken,
      businessId: this.item.patientPackageId,
      memberId: this.item.memberId,
      rightsOrderNo: this.item.rightsOrderNo,
      rightsId: this.item.rightsId,
      businessNo: this.item.orderNo,
      type: type,
    };

    let actionUrl =
      this.item.productsLink &&
      this.item.productsLink.replace(/\(\(userId\)\)/g, this.userInfo && this.userInfo.userId);

    let cancelParams = {
      appid: this.item.appletAppId,
      eleId: this.item.id, // 元素id
      url: actionUrl,
      content: this.reportContent, // 自定义的html内容
      extradata: JSON.stringify(data),
    };

    this.wx_launch(cancelParams);
  }

  /**
   * 跳转小程序封装
   * @protected
   * @returns void
   */
  private async wx_launch(info): Promise<void> {
    if (!(await tools.isWechat())) {
      return;
    }
    var btn = document.getElementById(info.eleId); //获取元素
    if (!btn) {
      return;
    }
    let script = document.createElement("script"); // 创建script内容插槽 避免template标签冲突
    script.type = "text/wxtag-template"; // 使用script插槽 必须定义这个type
    script.text = info.content; // 自定义的html字符串内容
    let html = `<wx-open-launch-weapp style="width:100%; height:100%;display:block;overflow: hidden;" appid="${info.appid}" path="${info.url}" env-version="trial" extra-data='${info.extradata}' >${script.outerHTML}</wx-open-launch-weapp>`;
    btn.innerHTML = html; // html字符串赋值
    // 点击按钮 正常跳转触发
    btn.addEventListener("launch", function (e) {
      console.log("success");
    });
    // 点击跳转 抛出异常
    btn.addEventListener("error", function (e) {
      console.log("fail", e);
      alert(`跳转异常 - ${JSON.stringify(e)}`);
    });
  }

  /**
   * 初始化jssdk
   * @private
   * @returns Promise<any>
   */
  private async init(): Promise<any> {
    if (await tools.isWechat()) {
      let url = location.href;
      let { content: result } = await WechatService.instance.getWechatJSSDK(url);
      if (result.data) {
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: result.data.appId, // 必填，公众号的唯一标识
          timestamp: result.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: result.data.nonceStr, // 必填，生成签名的随机串
          signature: result.data.signature, // 必填，签名
          jsApiList: ["openLocation"],
          openTagList: ["wx-open-launch-weapp"], // 必填，需要使用的JS接口列表
        });

        wx.ready(function () {});
      }
    }
  }

  /**
   * 跳转详情
   * @private
   * @returns string
   */
  private onDetail(data: any): any {
    if (data.productCategory == this.productCategoryMap.greenway) {
      // 绿通详情
      this.$router.push({ name: "greenway-detail", query: { id: data.id, isAi: this.isAi + "" } });
    } else {
      // 普通医疗订单详情
      this.$router.push({ name: "medical-order-detail", query: { id: data.id, isAi: this.isAi + "" } });
    }
  }

  /**
   * 取消预约
   * @protected
   * @returns void
   */
  protected async onCancelAppointment() {
    try {
      let { content: result } = await MedicalOrderService.instance.cancelAppointMedicalOrder(this.item.id);
      Toast("操作成功!");
      this.showCancelDialog = false;
      this.$emit("refresh");
    } catch (err) {
      Toast(JSON.stringify(err));
    }
  }

  /**
   * 预约服务订单
   * @protected
   * @returns void
   */
  protected onAppointment(id) {
    this.$router.push({ name: "medical-order-appointment", query: { id } });
  }

  /**
   * 绿通订单
   * @protected
   * @returns void
   */
  protected onToAppointment(id) {
    this.$router.push({ name: "greenway-reservation", query: { id } }); // 绿通
  }

  /**
   * 继续支付
   * @private
   * @param {params}
   * @returns {Promise<void>}
   */
  private async proceedPay(): Promise<void> {
    this.$router.push({ name: "pay", query: { orderNo: this.item.orderNo } });
    // try
    // {
    //     let {content: result} =  await MedicalOrderService.instance.proceedPayMedicalOrder(this.item.id);

    //     if(result)
    //     {
    //         if(result.data.isNeedPay)
    //         {
    //             // Pay.onBridgeReady(result.data, this.getOrderList);
    //             this.$router.push({name: "pay", query:{orderNo: result.data.orderNo}});
    //         }
    //         else
    //         {
    //             this.getOrderList();
    //         }

    //     }
    // }
    // catch(err)
    // {
    //     Toast(err);
    // }
  }

  /**
   * 确定改约
   * @private
   * @returns void
   */
  private onDateConfirm(date: string, physicalTimePeriod: string, id: any): any {
    if (!date) return;

    let data = dayjs(date).format("YYYY-MM-DD");
    Dialog.confirm({
      title: "改约日期",
      message: `是否确定改约${data} ${physicalTimePeriod}体检`,
    }).then(async () => {
      try {
        let data = {
          id: id,
          changeTime: dayjs(date).format("YYYY/MM/DD"),
          timePeriod: physicalTimePeriod,
        };

        let { content: result } = await MedicalOrderService.instance.changeMedicalOrderSchedule(data);

        Toast(result?.msg == "请求成功" ? "改约成功" : result?.msg);

        this.$emit("refresh");
      } catch (err) {
        Toast(err);
      } finally {
        this.showDate = false;
      }
    });
  }

  /**
   * 继续支付
   * @private
   * @param {params}
   * @returns {Promise<void>}
   */
  private async getOrderList(): Promise<void> {
    this.$emit("refresh");
  }
}
