
import { Component, Vue, Prop } from "vue-property-decorator";
import { Toast, Dialog } from "vant";
import { Service } from "src/decorator";
import { RightsService, MedicalOrderService } from "src/services";
import dayjs from "dayjs";

@Component
export default class rights extends Vue {
  /**
   * 是否是ai跳转过来
   * @private
   * @returns boolean
   */
  private get isAi(): boolean {
    return this.$route.query && this.$route.query.isAi == "true" ? true : false;
  }

  /**
   * 选中id
   * @private
   * @returns number
   */
  private checkId: any = null;

  /**
   * 是否显示激活框
   * @private
   * @returns string
   */
  private showActivate: boolean = false;

  /**
   * 数据
   * @private
   * @returns string
   */
  @Prop({ type: Object, default: () => ({}) })
  private item: any;

  /**
   * 跳转详情
   * @private
   * @returns string
   */
  private onDetail(id): any {
    this.$router.push({ name: "rights-order-detail", query: { id, isAi: this.isAi + "" } });
  }

  /**
   * 预约服务订单
   * @protected
   * @returns void
   */
  protected onAppointment(id) {
    this.$router.push({ name: "medical-order-appointment", query: { id, isAi: this.isAi + "" } });
  }

  /**
   * 查看权益
   * @private
   * @returns void
   */
  private onToRights(id: any): void {
    this.$router.push({ name: "rights-detail", query: { id: id + "", isAi: this.isAi + "" } });
  }

  /**
   * 激活
   * @private
   * @returns void
   */
  private async onActive(): Promise<void> {
    try {
      await RightsService.instance.clientActive(this.checkId);
      Toast("激活成功");
      this.$emit("refresh");
      this.showActivate = false;
      setTimeout(() => {
        this.$router.push({ name: "rights-detail", query: { id: this.checkId + "", isAi: this.isAi + "" } });
      }, 50);
    } catch (err) {
      Toast(err);
    }
  }
}
