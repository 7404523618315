
import { Component, Vue } from "vue-property-decorator";
import { OrderService } from "src/services";
import { Toast } from "vant";
import { Payment_Type } from "src/enums";
import { tools } from "src/utils";
import globalConfig from "src/config/index";
import { locale } from "dayjs";
declare let WeixinJSBridge: any;

@Component
export default class Login extends Vue {
  private payType: string = "1";

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  /**
   * 组件创建钩子
   * @private
   * @returns string
   */
  private created() {
    if (this.employerConfig?.iosRedirectUrl) {
      let url = this.employerConfig?.iosRedirectUrl;
      if (this.employerConfig.iosRedirectUrl.indexOf("${url}")) {
        url = this.employerConfig.iosRedirectUrl.replace(
          "${url}",
          encodeURIComponent(
            window.location.href.replace("order/pay/redirectUrl?id=", "order/detail/") + "?isPaySuccessPage=true"
          )
        );
      }

      window.location.assign(url);
    } else {
      this.$router.replace({ name: "login" });
    }
  }
}
