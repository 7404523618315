
import { Component, Vue } from "vue-property-decorator";
import { GaodeService, OrganizationService, PackageService, OrderService, FamilyService } from "src/services";
import { Toast, Dialog } from "vant";
import dayjs from "dayjs";
import { CHECK_STATUS, ORDER_STATUS } from "src/enums";
import { EnumUtils, tools } from "src/utils";
// import sensors from '../../sensors'

@Component
export default class OfteUser extends Vue {
  /**
   * 订单状态
   * @private
   * @returns number
   */
  private get orderStatus(): any {
    if (this.$route.query && this.$route.query.orderStatus) {
      if (+this.$route.query.orderStatus == 77) {
        document.title = "提交成功";
      }
      return this.$route.query.orderStatus;
    }
    return null;
  }

  /**
   * 订单id
   * @private
   * @returns number
   */
  private get orderId(): string {
    if (this.$route.query && this.$route.query.id) {
      return this.$route.query.id + "";
    }

    return null;
  }

  /**
   * 是否包含非体检
   * @private
   * @returns number
   */
  private get hasHealthProduct(): boolean {
    if (this.$route.query && this.$route.query.hasHealthProduct) {
      return !!this.$route.query.hasHealthProduct;
    }

    return null;
  }

  /**
   * 是否拥有配置了套餐
   * @private
   * @returns boolean
   */
  public hasFamilyPkg: Boolean = false;

  /**
   * 查询是否所配置的套餐数量
   * @private
   * @returns boolean
   */
  private async getPkgRight(): Promise<void> {
    try {
      let { content: result } = await OrderService.instance.checkFamilyPackage();
      if (result.data?.hasFamilyPackage) {
        this.hasFamilyPkg = result.data?.hasFamilyPackage;
      } else {
        this.hasFamilyPkg = false;
      }
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 组件创建钩子
   * @protected
   * @returns void
   */
  protected async created() {
    // 监听浏览器返回动作
    window.addEventListener("popstate", this.onWeatchMiniBack, true);
    this.getPkgRight();
  }

  /**
   * 跳转订单详情
   * @private
   * @returns number
   */
  private onOrderDetail(): void {
    this.$router.push({ name: "order-list" });
  }

  /**
   * 跳转订单详情
   * @private
   * @returns number
   */
  private async onPersonalCenter() {
    this.$router.push({ name: "personal-center", query: { isCloseMiniWx: "true" } });
  }

  /**
   * 跳转套餐列表
   * @private
   * @returns number
   */
  private onPackageList(): void {
    // sensors.track('TJ_MEA_Click', {
    //     me_channel: '家属体检',
    //     current_page: "TJ-体检预约成功页",
    // });

    this.$router.push({ name: "physical-package", query: { type: "2" } });
  }

  /**
   * 获取企业参数配置
   * @private
   * @returns any
   */
  private get generalSettings(): any {
    let generalSettingsValue = localStorage.getItem("generalSettings");
    if (generalSettingsValue) {
      return JSON.parse(generalSettingsValue);
    }

    return [];
  }

  // 是否显示个人版
  protected get showPersonal(): boolean {
    // A1004  -- 是否显示个人版入口
    let data = this.generalSettings.filter((res) => res.code == "A1004");

    if (data.length > 0) {
      return data[0].isOpen;
    } else {
      return false;
    }
  }

  protected onWeatchMiniBack = async () => {
    if (await tools.isWeatchMini()) {
      (wx.miniProgram as any)?.navigateBack(); // 退回到小程序
    } else {
      this.$router.push({ name: "home" });
    }
  };

  protected async destroyed() {
    setTimeout(() => {
      window.removeEventListener("popstate", this.onWeatchMiniBack, true);
    }, 100);
  }
}
