
import { Component, Vue, Prop } from "vue-property-decorator";
import { Toast } from "vant";
import { Service } from "src/decorator";
import { OrderService, GoodsOrderService } from "src/services";
import dayjs from "dayjs";

@Component
export default class Goods extends Vue {
  /**
   * 是否是ai跳转过来
   * @private
   * @returns boolean
   */
  private get isAi(): boolean {
    return this.$route.query && this.$route.query.isAi == "true" ? true : false;
  }

  /**
   * 数据
   * @private
   * @returns string
   */
  @Prop({ type: Object, default: () => ({}) })
  private item: any;

  /**
   * 是否显示确认收货弹框
   * @private
   * @returns
   */
  private showPopup: boolean = false;

  /**
   * 跳转详情
   * @private
   * @returns string
   */
  private onDetail(id): any {
    this.$router.push({ name: "goods-order-detail", query: { id, isAi: this.isAi + "" } });
  }

  /**
   * 确认收货
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async confirmGoodsOrder(): Promise<void> {
    try {
      await GoodsOrderService.instance.confirmGoodsOrder(this.item.id);
      Toast("操作成功");
      this.$emit("refresh");
      this.showPopup = false;
    } catch (err) {
      Toast(JSON.stringify(err));
    }
  }

  /**
   * 撤销申请
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async onRevoke(): Promise<void> {
    try {
      await GoodsOrderService.instance.onRevokeGoods(this.item.id);
      Toast("操作成功");
      this.$emit("refresh");
    } catch (err) {
      Toast(JSON.stringify(err));
    }
  }
}
